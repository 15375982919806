import React, { useState, useEffect } from "react";
import Testimonals from "../Testimonals";
import Services from "./Services";
import Accomplishments from "./Accomplishments";
import { doc, setDoc, collection } from "firebase/firestore";
import { db } from "../Firebase";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "./home.css";
// import NearestHospital from "./NearestHospital";
const HomePage = () => {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [index, setIndex] = useState(0);

  const texts = [
    "Transforming data into care 🌐🏥",
    "Algorithms into cures 💻💊",
    "Technology into a healthier future 🔬🌱",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 3000);
    return () => clearTimeout(intervalId);
  }, []);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setValidEmail(false);
    setSubscribed(false);
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setValidPhoneNumber(false);
    setSubscribed(false);
  };
  const handleSubmition = () => {
    const emailRegex =
      /^[a-zA-Z0-9][a-zA-Z0-9._+-]*@[a-zA-Z0-9.-]+(?:\.[a-zA-Z]{2,})+$/;
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    if (!emailRegex.test(Email)) {
      setValidEmail(true);
      setSubscribed(false);
      return;
    }
    if (!phoneRegex.test(PhoneNumber)) {
      setValidPhoneNumber(true);
      setSubscribed(false);
      return;
    }

    try {
      const collectionRef = collection(db, "subscription"); // Specify the collection name
      const docRef = doc(collectionRef, Email); // Create a document reference with the custom ID
      const data = { phoneNumber: PhoneNumber };
      setDoc(docRef, data).then(() => {
        setSubscribed(true);
        setValidEmail(false);
        setValidPhoneNumber(false);
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  const variants = {
    enter: { opacity: 0, x: 30 },
    center: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50 },
  };

  return (
    <div className="min-h-screen bg-white text-gray-900">
      {/* Hero Section */}
      <section className="bg-white pt-14">
        <div className="container mx-auto flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 text-center ml-4 lg:ml-20 xl:ml-40 md:text-left">
            <h1 className="text-[36px] sm:text-[40px] font-bold relative bg-white z-10 leading-10 text-left">
              CLINICAL AI ASSISTANCE
            </h1>
            <div className="text-sm md:text-lg text-left p-0 text-teal-600 font-bold mb-2 max-w-80 md:max-w-96">
              <motion.div
                key={index}
                initial="enter"
                animate="center"
                exit="exit"
                variants={variants}
                transition={{ duration: 1 }}
              >
                {texts[index % texts.length]}
              </motion.div>
            </div>
            <p className="text-sm sm:text-base md:max-w-xl mb-4 text-justify p-0 pr-8">
              We empower both healthcare providers and seekers through various
              AI technologies. We partnered with several organizations and served 6,500+ users through our products last quarter.
            </p>
            <div className="hidden md:block">
              <button
                onClick={() => navigate("/signup")}
                className="bg-gradient-to-r from-teal-500 to-teal-800 text-white px-6 py-2 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              >
                Sign Up
              </button>
              <button
                onClick={() => navigate("/login")}
                className="ml-4 bg-gradient-to-r from-teal-500 to-teal-950 bg-clip-text text-transparent px-7 py-2 rounded-full border-2 border-teal-600 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Log In
              </button>
            </div>
          </div>
          <div className="md:w-1/2 px-4 md:px-0">
            <img
              src="./img/homeimage2.png"
              alt="AI Assistance"
              className="w-auto h-[35vh] lg:h-[40vh] mx-auto rounded-lg shadow bg-teal-100 px-16 md:px-0"
            />
            <div className="md:hidden mt-4 flex flex-col justify-start ml-1">
              <button
                onClick={() => navigate("/signup")}
                className="bg-gradient-to-r w-full from-teal-500 to-teal-800 text-white px-6 py-2 rounded-full"
              >
                Sign Up
              </button>
              <button
                onClick={() => navigate("/login")}
                className="bg-gradient-to-r w-full mt-2 from-teal-500 to-teal-950 bg-clip-text text-transparent px-7 py-2 rounded-full border-2 border-teal-600"
              >
                Log In
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Doctor aayush */}
      <section className="pt-20 bg-white sm:ml-2">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-2 sm:mb-4">
            Meet{" "}
            <span className="bg-gradient-to-r from-teal-500 to-teal-950 bg-clip-text text-transparent">
              Dr. Aayush
            </span>
          </h2>
          <div className="flex sm:ml-2  px-3 lg:px-0 text-left">
            <div className="flex-2 sm:flex-1 lg:ml-20 xl:ml-40 text-justify text-xs sm:text-sm md:text-base ">
              <p className="mt-6">
                Did you know that the world lacks 7.2 million medical workers,
                and this number is expected to grow to 30 million by 2030? With
                the motivation of assisting doctors, we developed an autonomous junior healthcare assistant
                called Dr. Aayush, which serves as the initial point of contact
                for patients in hospitals, particularly OPD wards. Dr. Aayush
                conducts an initial assessment and compiles a concise report,
                sends it to the doctor, and saves 30% of doctors' time. 
              </p>
              <button
                onClick={() => navigate("/DrAayush")}
                className="bg-gradient-to-r mt-4 from-teal-500 to-teal-800 text-white px-6 py-2 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              >
                Learn More
              </button>
              <button
                onClick={() => {
                  window.open(
                    "https://cal.com/clinical-ai-assistance/dr-aayush-demo-call",
                    "_blank"
                  );
                }}
                className="bg-gradient-to-r from-teal-500 to-teal-950 bg-clip-text text-transparent border-2 border-teal-600 ml-4 mt-4 px-6 py-2 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              >
                Book a Demo
              </button>
            </div>
            <div className="flex-1">
              <img
                src="./img/doc_aayush.png"
                alt="Dr. Aayush"
                className="w-72 h-72 my-0 mx-auto hidden sm:flex "
              />
            </div>
          </div>
          <div className="xl:mx-32 px-2">
            <img
              src="./img/aayush2.png"
              alt="Dr Aayush"
              className="w-full h-32 sm:ml-2 sm:h-auto"
            />
          </div>
        </div>
      </section>

      <section className="bg-white pt-16 pb-20 md:mx-4 lg:mx-12 xl:mx-40 ">
        <Services />
      </section>

      <section className="md:mx-4 xl:mx-40 bg-white">
        <Accomplishments />
      </section>

      <section className="mb-11">
        <Testimonals />
      </section>
      {/* Search Hospital */}
      {/* <section className="mb-11">
        <NearestHospital />
      </section> */}

      <section className="pt-10 pb-10 bg-white mx-2 sm:mx-20 lg:mx-40">
        <h2 className="text-3xl font-bold mb-4 text-center">
          Subscribe to get
          <br />
          <span className="bg-gradient-to-r from-teal-500 to-teal-950 bg-clip-text text-transparent">
            Important updates
          </span>
        </h2>
        <div className="container mx-auto text-center bg-teal-100 py-14 rounded-md">
          <p className="max-w-2xl mx-auto mb-8">
            Join 2,000+ subscribers for weekly updates on medical technologies
            and AI advancements.
          </p>

          <div className="flex flex-col md:flex-row justify-center items-center md:space-x-4 mx-8 sm:mx-10 lg:mx-40">
            <div className="flex flex-col w-full my-2 relative outline-none">
              <input
                type="email"
                placeholder="Enter Email"
                value={Email}
                onChange={handleEmailChange}
                className="px-4 py-[5px] my-auto rounded border border-gray-300 focus:outline-none"
              />
              <div className="absolute mt-36 md:mt-12 ml-2">
                {validEmail && (
                  <p className="email-msg text-red-500">
                    Enter a valid Email address
                  </p>
                )}
                {subscribed && (
                  <p className="subscribed-msg text-teal-600 w-[48vw]">
                    Subscribed Successfully!
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col w-full relative">
              <PhoneInput
                className=""
                defaultCountry={"in"}
                value={PhoneNumber}
                onChange={handlePhoneNumberChange}
              />
              <div className="absolute mt-28 md:mt-12 ml-2">
                {validPhoneNumber && (
                  <p className="phone-msg text-red-500">
                    Enter a valid Phone number
                  </p>
                )}
              </div>
            </div>
            <button className="bg-gradient-to-r w-full md:w-auto mt-2 md:mt-0 from-teal-500 to-teal-800 text-white px-6 py-2 rounded-full transition duration-300 transform ease-in-out hover:scale-105">
              <h2 onClick={handleSubmition}>Subscribe</h2>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
